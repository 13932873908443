import {
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import searchQuestion from "../funcitons/searchQuestion";
import axiosErrorHandler from "../utils/axiosErrorHandler";

export default function SearchAnswer() {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    if (!loading && !!searchTerm) {
      try {
        setLoading(true);
        const resp = await searchQuestion(searchTerm);
        setResults(resp);
      } catch (e) {
        axiosErrorHandler(e);
        setResults([]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <TextField
        label="Search Questions"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyPress={(e) => e.key === "Enter" && handleSearch()}
        margin="normal"
        required
        fullWidth
        id="question"
        name="question"
        autoComplete="search"
        autoFocus
      />

      <Button
        variant="outlined"
        onClick={handleSearch}
        sx={{ mt: 2, minHeight: "2.875rem", minWidth: "6.375rem" }}
      >
        {loading ? (
          <CircularProgress
            color="primary"
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          "Search"
        )}
      </Button>
      <Box sx={{ mt: 2 }}>
        {results.length > 0 ? (
          results.map((result) => (
            <>
              <Box key={result._id} sx={{ mt: 1, mb: 1 }}>
                <Typography variant="h6" sx={{ color: "#A782C3" }}>
                  Answer:
                </Typography>
                <Typography>{result.answer}</Typography>
                <Typography sx={{ color: "#A782C3" }}>Score:</Typography>
                <Typography>{result.score}</Typography>
                <Typography sx={{ color: "#A782C3" }}>
                  Relevance Score:
                </Typography>
                <Typography>{result.relevance}</Typography>
                <Typography sx={{ color: "#A782C3" }}>
                  Original Question:
                </Typography>
                <Typography>{result.question}</Typography>
                <Typography sx={{ color: "#A782C3" }}>ID:</Typography>
                <Typography>{result._id}</Typography>
              </Box>
              <Divider />
            </>
          ))
        ) : (
          <Typography>No results found.</Typography>
        )}
      </Box>
    </>
  );
}
