import axios from "axios";

export default async function addQuestion(question, answer) {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/add`, {
    question,
    answer,
  });

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
