import axios from "axios";

export default async function searchQuestion(question) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/search?question=${question}`
  );

  if (!response.data) {
    throw new Error("Some error occurred while fetching.");
  }
  if (response.data.error) {
    throw new Error(response.data.error);
  }

  return response.data;
}
