import React, { useState } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import QuestionAnswerForm from "./components/QuestionAnswerForm";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import TabPanel from "./components/TabPanel";
import SearchAnswer from "./components/SearchAnswer";

const theme = createTheme({
  palette: {
    mode: "dark", // Use the dark mode to match your body background
    background: {
      default: "#363433", // Set the default background color
    },
  },
  components: {
    // Style all instances of TextField globally
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#ECECEC",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "#ECECEC",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#ECECEC",
            },
            "&:hover fieldset": {
              borderColor: "#ECECEC",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#ECECEC",
            },
            "& input": {
              color: "#ECECEC", // Change text color
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#A782C3",
          borderColor: "rgba(255, 255, 255, 0.1)",
          "& .MuiButtonBase-root": {
            color: "#A782C3",
          },
          "&.Mui-disabled": {
            cursor: "not-allowed !important",
          },
        },
      },
    },
  },
});

function App() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer />
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100dvh",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Add Question" />
              <Tab label="Search Answer" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <QuestionAnswerForm />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* Search Answer Tab Content */}
            <SearchAnswer />
          </TabPanel>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;
