import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import axiosErrorHandler from "../utils/axiosErrorHandler";
import addQuestion from "../funcitons/addQuestion";

export default function QuestionAnswerForm() {
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    if (!loading) {
      event.preventDefault();
      try {
        setLoading(true);
        const resp = await addQuestion(question, answer);
        if (resp.success) {
          toast.success("Question added successfully");
        }
      } catch (e) {
        axiosErrorHandler(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="question"
          label="Question"
          name="question"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          autoComplete="question"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="answer"
          label="Answer"
          type="text"
          id="answer"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          multiline
          rows={8}
          InputProps={{
            style: {
              resize: "both",
            },
          }}
        />
        <Button
          type="submit"
          // fullWidth
          disabled={!answer || !question}
          onClick={handleSubmit}
          variant="outlined"
          size="large"
          sx={{ mt: 3, mb: 2, minHeight: "3rem" }}
        >
          {loading ? (
            <CircularProgress
              color="primary"
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </>
  );
}
