import { AxiosError } from "axios";
import { toast } from "react-toastify";

export default function axiosErrorHandler(error) {
  console.log(error);
  if (error instanceof Error) {
    if (error instanceof AxiosError) {
      // console.log(error?.response?.data.error);
      toast.error(error?.response?.data.error);
    } else {
      // console.log(error.message);
      toast.error(error.message);
    }
  }
}
